import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const DebtThroughoutHistoryPage = () => {
    const article = {
        id: '64086fb9-0179-54d9-8200-c29474e7c5b7',
        title: 'An Exploration of Debt Throughout History',
        slug: '/debt-throughout-history/',
        date: '2018-01-22T17:46:13.000Z',
        modified: '2021-11-02T17:06:40.000Z',
        excerpt: 'From ancient Rome to the Great Recession, debt and credit have been pivotal to human history. Learn the major takeaways on the history of debt so you know how to manage your own creditworthiness.',
        featured_image: {
            source_url: '/media/debt-through-history.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 13,
        content: (
            <>
                <p>Debt has a bad reputation.</p>
                <p>On the one hand, everyone needs it at some point to finance a home or education. On the other, too much debt can be risky or even dangerous.</p>
                <p>The news is filled with reports of the debt crisis and whether the federal government should raise the &lsquo;debt ceiling&rsquo;, which is the difference between the amount the U.S. government has to spend and the amount it needs to cover what it doesn&rsquo;t.</p>
                <p>To put this into terms us normal consumers would understand, the U.S. has maxed out its credit limit—again and again. But since it&rsquo;s the government, it can make its own rules and raise its credit limit, i.e. its debt ceiling.</p>
                <p>
                    <LazyLoadImage src="/media/which-president-raised-the-debt-ceiling-the-most-1.png" alt="Which President raised the debt ceiling the most?" />
                </p>
                <p>Nice. Right?</p>
                <p>
                    The U.S. government has currently $20 trillion worth of debt. That&rsquo;s approximately
                    {' '}
                    <a href="http://www.usdebtclock.org/">$70,000 of debt for every American on the planet</a>
                    , according to the U.S. Debt Clock. This amount translates into 5% more than all the money the U.S. makes in one year.
                </p>
                <p>When FICO and credit bureaus like Equifax and TransUnion calculate your credit score, they consider, among many other things, how much of your available credit you have used over your credit limit, which is known as your debt utilization ratio.</p>
                <p>It&rsquo;s a good thing the U.S. government isn&rsquo;t getting a FICO score because it has used up all its credit.</p>
                <p>
                    Yet, the U.S. still gets a
                    {' '}
                    <a href="https://www.globalcreditportal.com/ratingsdirect/renderArticle.do?articleId=1780963&amp;SctArtId=412609&amp;from=CM&amp;nsl">AAA rating from S&amp;P Global</a>
                    , a credit ratings agency, due to its relative stability.
                </p>
                <p>Banks still believe that loaning the U.S. government money is a safe investment.</p>
                <p>
                    Just like how you may be in debt with a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=debt-throughout-history&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    , and can still get a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-throughout-history&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    with a high credit limit, so can the U.S. government. People trust their government will be able to pay back the loan–with interest–to cover its debts.
                </p>
                <p>
                    That is, governments and companies have debt&nbsp;just like we do. And like us, governments
                    {' '}
                    <em>and companies</em>
                    {' '}
                    sometimes spend more than they earn, giving them a high debt-to-income ratio.
                </p>
                <p>Everyone needs debt to pay for things coming up that they can&rsquo;t afford based on their current income.</p>
                <p>Going to school? You&rsquo;ll need a loan (debt). Want to expand your business? That&rsquo;s another loan and debt to be paid. Have a country to run? Promise you&rsquo;ll pay your &lsquo;investors&rsquo; back.</p>
                <p>
                    Debt has played a central role throughout human history. It even existed
                    {' '}
                    <em>before</em>
                    {' '}
                    the invention of money.
                </p>
                <p>So what? Well, in order to manage your own finances better, you have to know how the system works.</p>
                <p>Understanding debt will help you make smart decisions about money and set yourself up for financial success. It&rsquo;s okay to have debt — you just need&nbsp;to manage it intelligently.</p>
                <p>Here&rsquo;s a four-chapter crash course in the history of money, credit, and debt.</p>
                <p><strong>1. Money can be almost anything—including debt</strong></p>
                <p>From cattle to bitcoins, money has taken many forms in history. It can be whatever a society agrees upon as currency.</p>
                <p>
                    <LazyLoadImage src="/media/top-10-regions-that-are-willing-to-use-the-digital-currencies-2.png" alt="Top 10 regions that are willing to use the digital currencies" />
                </p>
                <p>Early humans had neither credit nor cash. They simply bartered, trading goods or services for mutual benefit.</p>
                <p>Still, even those primitive transactions required a measure of value.</p>
                <p>
                    Currency, of a kind,
                    {' '}
                    <a href="http://www.pbs.org/wgbh/nova/ancient/history-money.html">was invented around 9000 BC</a>
                    {' '}
                    when cows, sheep, and other livestock were used as money around the world. Around 1200 BC, cowrie shells were used as money in China.
                </p>
                <p>Items varied between societies, but the principle was the same. Native Americans used wampum, beads made of shells, while tribes in the Solomon Islands used feather money.</p>
                <p>People weren&rsquo;t usually buying goods in these transactions. They used primitive currency to settle more personal debts, like to resolve an argument or arrange a marriage.</p>
                <p><strong>Coinage and paper money are relatively new</strong></p>
                <p>What we think of as money debuted around 1000 BC when precious metal came into play.</p>
                <p>The Chinese used bronze and copper to craft mock cowrie shells or tools. The concept evolved into metal coins, usually made with a hole in the middle so they could be carried on a string.</p>
                <p>China, India, and Lydia (in present-day Turkey) all developed coins around 600 to 500 BC.</p>
                <p>
                    According to economic anthropologist David Graeber, author of
                    {' '}
                    <em>Debt: The First 5,000 Years</em>
                    ,
                    {' '}
                    <a href="http://www.huffingtonpost.com/david-graeber/history-debt_b_913419.html">coins came into use as a way to pay soldiers</a>
                    .
                </p>
                <p>&quot;Soldiers were the people originally most likely to be wandering around with chunks of (looted) gold and silver to begin with, to be looking for the good things in life, and — being heavily armed and itinerant — to be the last people in the world an ancient merchant would see as a good credit risk.</p>
                <p>Finally, rulers realized if they simply standardized the chunks of metal, stamped them with their official seal, and handed them directly to soldiers — and then demanded everyone else in their kingdom hand a certain number of them back as taxes–they could create the perfect way of forcing their subjects to feed and supply their armies.&quot;</p>
                <p>Coins got heavy, so the Chinese invented paper money in 806, which was easier to carry around. It was originally issued as a certificate in exchange for a load of cash but evolved into modern-style banknotes.</p>
                <p>Paper money did not become common in the rest of the world until the 1700s.</p>
                <p><strong>The Gold Standard is not so popular today</strong></p>
                <p>Unlike coins, paper money had no inherent value. Printing too much could lead to inflation, so, sometimes, countries tied the value of money to gold and silver.</p>
                <p>But fluctuations in supply and demand confused things. After the California Gold Rush, for instance, gold flooded the market, making silver the more valuable metal.</p>
                <p>In 1816, England instituted the Gold Standard, tying the value of banknotes to a certain amount of gold. The United States did the same in 1900.</p>
                <p>
                    <LazyLoadImage src="/media/world-officail-gold-reserves-3.png" alt="World officail gold reserves" />
                </p>
                <p>Many economists argue that the Gold Standard worsened the Great Depression of 1929. The US government could not print more money to combat deflation.</p>
                <p>Countries that came off of the Gold Standard recovered more quickly since they could use monetary policy to stabilize the economy. President Franklin D. Roosevelt suspended the Gold Standard in 1933.</p>
                <p>No country uses the system anymore.</p>
                <p><strong>Cash has its critics</strong></p>
                <p>Fears of inflation today have led some politicians, like Ron Paul, to advocate for a return to the Gold Standard.</p>
                <p>
                    In the New York Times, Floyd Norris says that
                    {' '}
                    <a href="http://www.nytimes.com/2012/02/03/business/in-rise-of-gold-bugs-history-repeats-itself.html">gold remains a subject of debate</a>
                    {' '}
                    among economists because it&rsquo;s a matter of belief.
                </p>
                <p>&quot;To supporters, gold has been money for thousands of years, and a return to it is the only way to keep politicians from (cheapening) currencies.</p>
                <p>To most current economists, gold is a commodity (which just means it&rsquo;s like anything that can be bought or sold). Gold is subject to the laws of supply and demand (when fewer people want it, the price goes down.) To economists, the supply of money should be controlled based on economic principles.</p>
                <p>With a gold standard, the amount of gold available to back money could grow only at the same rate that gold stocks increased, something that depends on mining successes, not on the needs of an economy.&quot;</p>
                <p>It seems unlikely that the United States will revert to the Gold Standard. Instead, new currencies more suited to our international, virtual economy are taking off.</p>
                <p>Invented in 2009, bitcoins aren&rsquo;t tied to any country or regulations.&nbsp;They can be freely traded online, across borders, and with no fees. Kind of like modern-day cowrie shells.</p>
                <p>
                    <LazyLoadImage src="/media/how-does-shopping-with-bitcoins-work-4.png" alt="How does shopping with Bitcoins work?" />
                </p>
                <p><strong>2. Credit and debt are over 5,000 years old</strong></p>
                <p>It&rsquo;s a common misconception that debt, credit, and interest are modern inventions.</p>
                <p>In fact, they&rsquo;ve been around for millennia.</p>
                <p>As early as 3500 BC, merchants in Mesopotamia inscribed debts onto clay tablets stamped with the borrower&rsquo;s seal.</p>
                <p>These ancient IOUs circulated as promissory notes—essentially money. The borrower had to pay back whoever had the tablet.</p>
                <p>Gold and silver coins wouldn&rsquo;t come into use for over 2,000 years. In other words, Graeber says, virtual money is the original form of currency. Those early loans even carried interest.</p>
                <p>In the 18th&nbsp;century BC, the Code of Hammurabi in Babylon, a city in Mesopotamia, inscribed the first known laws about debt and credit. Every loan needed a witness and written contract.</p>
                <p>It set maximum interest rates that could be charged: 33.3% on grain and 20% on silver.</p>
                <p>That implies that people often charged even higher interest rates.</p>
                <p>A lender often earned interest on the debt through the labor of a pledged slave or child. The law stipulated that debt servitude could not last more than three years.</p>
                <p>The borrower might also pledge personal property as an assurance that he was good for the money. This could be anything: land, slaves, houses—even wives.</p>
                <p>
                    <LazyLoadImage src="/media/the-global-slavery-index-2016-5.png" alt="The global slavery index 2016" />
                </p>
                <p>If a farmer took out a loan and had a bad harvest, he could lose everything. Many debtors fled home and joined nomadic tribes.</p>
                <p>When many people defaulted on their debts, societies faced potential unrest. Babylonian kings occasionally declared a general amnesty so people could have their land back.</p>
                <p><strong>Credit in ancient Rome</strong></p>
                <p>
                    In a discussion on the economy in ancient Rome, Marco Del Negro and Mary Tao of the New York Fed say that&nbsp;
                    <a href="http://libertystreeteconomics.newyorkfed.org/2012/11/historical-echoes-how-do-you-say-wall-street-in-latin.html">the empire basically ran on credit</a>
                    .
                </p>
                <p>&quot;Large sums of money changed hands in Roman times. People bought real estate, financed trade, and invested in the provinces occupied by the Roman legions.&quot;</p>
                <p>How? Citizens regularly took out loans, which were tallied in creditors&rsquo; and debtors&rsquo; account books.</p>
                <p>Money lenders and citizens met at the Forum, Rome&rsquo;s version of Wall Street. It even kept a list of delinquent debtors, like today&rsquo;s credit ratings.</p>
                <p>The system was surprisingly similar to what we have today. Consider this contemporary-sounding passage from author Pliny the Younger, circa 100 AD:</p>
                <p>&quot;Perhaps you will ask whether I can raise these three million without difficulty. Well, nearly all my capital is invested in land, but I have some money out at interest and I can borrow without any trouble.&quot;</p>
                <p><strong>Debt in the middle ages</strong></p>
                <p>In medieval Europe, people commonly used tally sticks to keep accounts. They carved notches into a piece of wood to denote what someone owed.</p>
                <p>The tally stick would be broken in half: One piece, &quot;the stock&quot; went to the creditor, and the other, &quot;the stub&quot; went to the debtor. (Thus the origin of the term &quot;stockholder,&quot; says Graeber.)</p>
                <p>Stocks traded hands like currency. They couldn&rsquo;t be forged since the pieces had to fit together.</p>
                <p>
                    Medieval loan sharks, like Shylock in Shakespeare&rsquo;s
                    {' '}
                    <em>The Merchant of Venice</em>
                    , spurred debates about the morality of usury.
                </p>
                <p>In 1545, England established a 10% legal rate of interest. Anyone caught charging more would be fined triple the interest.</p>
                <p>High-interest rates were the norm for much of history, making today&rsquo;s interest rates seem modest by comparison.</p>
                <p>
                    In fact, according to Andrew G. Haldane, chief economist of the Bank of England, contemporary interest rates are
                    {' '}
                    <a href="http://www.bankofengland.co.uk/publications/Documents/speeches/2015/speech797.pdf">lower than at any time in the last 5,000 years</a>
                    .
                </p>
                <p><strong>3. Financial systems evolved to trade and manage debt</strong></p>
                <p>As countries modernized and traded internationally, financial systems got more complicated. A growing middle class could spend more freely and was willing to make investments.</p>
                <p>It only became possible to trade shares of company stock in the 17th&nbsp;century. (Early markets like the Roman Forum traded promissory notes.)</p>
                <p>In 1602, the Dutch East India company established the world&rsquo;s first stock market: the Amsterdam Stock Exchange.</p>
                <p>
                    <LazyLoadImage src="/media/leading-stock-exchanges-worldwide-2017-6.png" alt="Leading stock exchanges worldwide 2017" />
                </p>
                <p>Investors loaned money to the company and bought shares to fund its voyages to India. When ships returned home, they got a cut of the proceeds. Shares were held on slips of paper that could be traded in Amsterdam.</p>
                <p>The London Stock Exchange opened in 1773 and the New York Stock Exchange in 1792.</p>
                <p>It became possible to buy government bonds around the same time. The Bank of England was established in 1694 to raise money for a war against France. By buying bonds, people could loan money to the government.</p>
                <p>United States Treasury bonds served the same purpose to fund World War I. Today, they&rsquo;re a way for the government to raise money in addition to taxation.</p>
                <p>
                    Treasury bonds and notes make up most of the national debt (or public debt), which now runs around $20 trillion,
                    {' '}
                    <a href="https://fred.stlouisfed.org/series/GFDEBTN">according to the St. Louis Fed</a>
                    , which is around 103% of GDP.
                </p>
                <p>Many politicians argue that this is a debt crisis that needs to be managed in a manner similar to how&nbsp;individuals stay out of debt. Just as consumers shouldn&rsquo;t spend more than they earn, so the debt-to-GDP ratio should be lower.</p>
                <p>To rein in the public debt, politicians say that the Treasury needs to set a debt limit (or debt ceiling) and restrict its issuance of bonds, kind of like when you resolve to cap your credit-card spending. The debt limit would reduce borrowing so it makes up a lower percent of GDP.</p>
                <p><strong>The need for credit ratings</strong></p>
                <p>Creditworthiness was long measured on the basis of reputation. A Roman trader was less likely to get a loan if he was considered untrustworthy.</p>
                <p>Credit reporting was formalized in Great Britain. In 1803, a group of English tailors established the Mutual Communication Society of London, in which they exchanged information on bad customers.</p>
                <p>The tailors realized that the people who did not settle their debts with one shop often went on to cheat others. They shared their names to protect themselves from potential credit risks.</p>
                <p>Other trade organizations did the same. The Manchester Guardian Society publicized its findings in weekly reports about credit risks in the area.</p>
                <p>In 1841, the Mercantile Agency became the first credit reporting company in the United States. It developed a system to track and rate the creditworthiness of a business or consumer.</p>
                <p>Moody&rsquo;s, a reporting agency for businesses, published its first manual in 1900. It began by collecting information on the strength of stocks, bonds, and industries. Then it developed a rating system based on its analysis of the strength of an investment.</p>
                <p>
                    <LazyLoadImage src="/media/americas-best-investment-options-7.png" alt="America's best investment options" />
                </p>
                <p>The Retail Credit Company was founded in 1899 in Atlanta to share credit information about consumers. It later changed its name to Equifax, which is the oldest of the three major US credit bureaus.</p>
                <p>It&rsquo;s also the most infamous. The 2017 Equifax hack—which leaked data on more than 143 million people—revealed the risks of collecting so much consumer information. Now&nbsp;we have to learn how to protect our personal details.</p>
                <p><strong>4. Consumer debt has grown since 1900</strong></p>
                <p>
                    Over the 20th&nbsp;century, Americans took on more and more debt.
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-throughout-history&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">Credit cards</a>
                    {' '}
                    allowed them to purchase everyday items with a short-term loan.
                </p>
                <p>The first credit cards came into use in the 1920s when companies like hotel chains issued them for use at their own locations.</p>
                <p>In 1950, Diners Club introduced the first universal credit card, which could be used in many places. American Express introduced a travel and entertainment credit card in 1958.</p>
                <p>Bank credit cards were next. They started on a local level, with Bank of America&rsquo;s statewide card for California in 1958. It went national in the 1960s and was renamed Visa in 1976.</p>
                <p>
                    In the late 20th&nbsp;century, credit card use rose dramatically. In 2017,
                    {' '}
                    <a href="http://www.foxbusiness.com/features/2017/09/09/this-is-how-much-credit-card-debt-average-american-holds.html">the average American household had over $16,000 in credit card debt</a>
                    , according to data from the Federal Reserve and Census Bureau.
                </p>
                <p><strong>The Great Recession hurt</strong></p>
                <p>Many economists believe that an increase in household debt was a major cause of the Great Recession of 2008-9.</p>
                <LazyLoadImage src="/media/emploment-one-year-after-the-great-recession-8.png" alt="Emploment one year after the Great Recession" />
                <p>In the 1950s, consumers rarely took out loans that exceeded their income. They averaged debts of 31% of their income.</p>
                <p>By 2000, the average American had debt of 81% of their income.</p>
                <p>In the early 2000s, at the peak of the housing boom, household debt was around 1.2 times larger than personal income. Americans took out mortgages, car loans, and credit card debt, balancing&nbsp;multiple payments&nbsp;that outpaced their earnings.</p>
                <p>
                    Data from the Federal Reserve
                    {' '}
                    <a href="/consumer-debt-in-america/">show how spending slowly rose before the recession</a>
                    . Carlos Garriga, Bryan Noeth, and Don E. Schlagenhauf of the St. Louis Fed argue that
                    {' '}
                    <a href="https://files.stlouisfed.org/files/htdocs/publications/review/2017-04-12/household-debt-and-the-great-recession.pdf">this exasperated the Great Recession</a>
                    .
                </p>
                <p>&quot;Innovations in housing finance [ie subprime loans] played an important role in the expansion of homeownership&hellip;the increase in the availability of mortgage debt was a key driver of increases in housing prices.</p>
                <p>The collapse of house values—used as collateral for borrowing—combined with the slow recovery in household income likely help explain the sluggish recovery.&quot;</p>
                <p>As people defaulted on their loans, they could no longer rely on credit for daily purchases and stopped spending money. This caused the economy to contract.</p>
                <p>If we were in Mesopotamia, most of us would have lost our land and children in the bargain.</p>
                <p>Just like in ancient times, when kings declared a general amnesty, the federal government had to step in to prevent a societal breakdown.</p>
                <p>It bailed out banks to stabilize the economy: Without access to capital, businesses couldn&rsquo;t finance their normal activities.</p>
                <p><strong>What you need to know now about debt</strong></p>
                <p>Credit and debt are as old as the wheel. Like that invention, both have been essential to human and economic progress.</p>
                <p>At the same time, debt has always carried risks. While the mechanics of finance has changed over history, the golden principle remains the same.</p>
                <blockquote><p>Don&rsquo;t spend what you won&rsquo;t be able to repay.</p></blockquote>
                <p>Otherwise, it may cost you everything.</p>
                <p><strong>The key takeaways about the history of debt</strong></p>
                <ul className="list">
                    <li>Money can be almost anything—including debt</li>
                    <li>Credit and debt are over 5,000 years old</li>
                    <li>Financial systems evolved to trade and manage debt</li>
                    <li>Consumer debt has grown since 1900</li>
                </ul>
                <p>Since you&rsquo;re interested in debt and credit, check out these articles for more:</p>
                <ul className="list">
                    <li>Consumer debt in America</li>
                    <li>68 financial experts explain how to get out of debt</li>
                    <li>4 signs you&rsquo;re perfect for debt consolidation</li>
                    <li>How to never get into debt again</li>
                </ul>
                <p>Did we miss anything about the history of debt? Let everyone know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default DebtThroughoutHistoryPage;
